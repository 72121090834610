<template>
  <div class="Coupon_I">
    <div class="headNavs">
      <div class="headTilte">优惠券管理</div>
      <div class="headDetails">说明：包含优惠券名称、门槛金额、优惠金额、有效时间、使用范围及添加时间</div>
    </div>

    <!-- 搜索导航 -->
    <div class="searchNav">
      <div class="NavLi">
          <div class="NavLiName">优惠券名称：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.name" placeholder="全部">
              <el-option v-for="item in tableData" :key="item.coupon_id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">使用范围：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.dedicated" placeholder="请选择使用范围">
              <el-option v-for="(item, key) in statisticsColumns.dedicated" :key="key" :label="item" :value="key"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">生效方式：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.instant" placeholder="请选择生效方式">
              <el-option v-for="(item, key) in statisticsColumns.instant" :key="key" :label="item" :value="key"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">类型：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.type" placeholder="请选择类型">
              <el-option v-for="(item, key) in statisticsColumns.type" :key="key" :label="item" :value="key"></el-option>
            </el-select>
          </div>
          <div class="NavLiQuery" style="margin-left:24px" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="NavLiQuery" style="background-color: rgb(37, 184, 100)" @click="grantAdd()"><i class="el-icon-plus"></i>发放优惠券</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入优惠券名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column prop="coupon_id" label="ID" width="60" align="center"></el-table-column>
        <el-table-column prop="name" label="优惠券名称"  align="center" width="140"></el-table-column>
        <el-table-column prop="total" label="优惠券门槛金额"  align="center" width="160"></el-table-column>
        <el-table-column prop="discount" label="优惠金额"  align="center"></el-table-column>
        <el-table-column prop="num" label="发放数量" align="center"></el-table-column>
        <el-table-column prop="instant" label="生效方式"  align="center" width="140">
          <template #default="scope">
              <span>{{{0:'领取后生效',1:'有效期内生效'}[scope.row.instant||0]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="time_text" label="有效时间" width="200" align="center"></el-table-column>
        <el-table-column prop="dedicated" label="使用范围"  align="center" width="140">
          <template #default="scope">
              <span>{{ {0:'全部商品有效',1:'指定商品有效'}[scope.row.dedicated||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type_text" label="类型" width="138" align="center"></el-table-column>
        <el-table-column prop="status" label="状态"  align="center" width="100">
          <template #default="scope">
              <span>{{ {0:'隐藏',1:'显示'}[scope.row.status||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间" width="150" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="140" >
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row">
          <el-form-item class="EditNewFormItem" prop="name">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>优惠券名称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请填写优惠券名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="type">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.type" multiple placeholder="请选择类型" style="width: 100%;">
                <el-option v-for="(item, key) in statisticsColumns.type" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>服务商</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="category.is_business" placeholder="请选择生效服务商" style="width: 100%;">
                <el-option v-for=" (item , key)  in statisticsColumns.is_business" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year" v-if="category.is_business == 1">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>指定服务商</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="category.business_id" multiple placeholder="请选择指定服务商" style="width: 100%;" >
                <el-option v-for=" item in BusinessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>生效方式</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="category.instant" placeholder="请选择生效方式" style="width: 100%;" >
                <el-option v-for="(item, key) in statisticsColumns.instant" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year" v-if="category.instant == 1">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>生效日期</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="category.start_time" required  type="datetime" placeholder="请选择生效日期"/>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year" v-if="category.instant == 1">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>截止日期</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="category.end_time" required  type="datetime" placeholder="请选择截止日期" />
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>使用范围</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.dedicated" placeholder="请选择使用范围" style="width: 100%;" >
                <el-option v-for="(item, key) in statisticsColumns.dedicated" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year" v-if="Row.dedicated == 1">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>专用券产品</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.goods_ids" multiple placeholder="请选择专用券产品" style="width: 100%;">
                <el-option v-for="item in Ordinary_list" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否启用" >
                <el-option v-for="(item, key) in statisticsColumns.status" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>领取后有效天数</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="category.time" placeholder="请填写领取后有效天数"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>优惠劵门槛金额</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.total" placeholder="请填写优惠劵门槛金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>优惠金额</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.discount" placeholder="请填写优惠金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>优惠券描述</div>
            <div class="EditNewFormItemIpt">
              <el-input type="tel" v-model="Row.description" placeholder="一句话介绍一下优惠券"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!--发放优惠券-->
    <el-dialog v-model="dialogVisible" title="发放优惠券" width="25%" :before-close="handleClose">
      <el-form class="EditNewForm" style="padding: 0px;">
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>用户</div>
          <div class="EditNewFormItemIpt">
            <el-select  v-model="send.user_id" multiple placeholder="请选择需要发放优惠券的用户 （多选）">
              <el-option v-for="item  in customerList" :key="item.id" :label="item.username" :value="item.id"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>优惠券</div>
          <div class="EditNewFormItemIpt">
            <el-select v-model="send.coupon_id" multiple placeholder="请选择要发放的优惠券 （多选）">
              <el-option v-for=" item  in tableData" :key="item.coupon_id" :label="item.name" :value="item.coupon_id"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>发放数量</div>
          <div class="EditNewFormItemIpt">
            <el-input type="number" v-model="send.num"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div class="FormBtnsQuery" @click="grantBtnAdd()">确认</div>
            <div class="FormBtnsClose" @click="dialogVisible = false">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      statisticsColumns:[], //统计列表
      BusinessList:[],  //服务商列表
      Ordinary_list:[],  //专用券产品
      customerList:[], // 用户名称
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      Row: { 
        name: "",
        type: [],
        dedicated: "",
        status: "",
        discount: '',
        total: "",
        description: "",
        goods_ids:[],
      },
      category: {
        is_business:'',
        instant: "",
        time:'',
        business_id:[],
        start_time:'',
        end_time:''
      },
      send:{
        user_id:[],
        coupon_id:[],
        num:'',
      },
      //搜索参数
      search: {
        name: "", 
        dedicated: "",
        type: "",
        instant: "",
        keyword: "",
      },
      addPanel: false,
      editPanel: false,
      dialogVisible:false,  // 弹出层控制
      editId: 0,  //删除id 
      total: 0,
      titleMath:'',
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios({                        //统计列表
       method: 'post',
       url: '/admin/order/coupon/statisticsColumns',
     }).then(function (res) {
       that.statisticsColumns = res.data.data
    })
    axios.get('/admin/ajax/businessList',{   // 服务商
      }).then(function (res) {
        that.BusinessList = res.data.data
    })
    axios({                   //  专用券产品
      method: 'post',
      url: '/admin/goods/goods/index',    
    }).then(function (res) {
      that.Ordinary_list = res.data.data.rows
    })
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/coupon/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    //  获取用户名称
    custome(){
      var that = this
      axios({                   
        method: 'post',
        url: '/admin/ajax/customerList',    
      }).then(function (res) { 
        that.customerList = res.data.data
      }) 
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          name: "", 
          dedicated: "",
          type: "",
          instant: "",
          keyword: "",
        }
      }
      that.loadTable();
    },
    // 发送打开
    grantAdd(){
      var that = this
      that.dialogVisible = true
       that.send = {
        user_id:'',
        coupon_id:'',
        num:1,
      }
      that.custome()
    }, 
    grantBtnAdd(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/coupon/send',
        data: {
          row: that.send,
        },
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.dialogVisible = false
          that.loadTable();
        }
      })
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        name: "",
        type: [],
        goods_ids:[],
        dedicated: "",
        status: "",
        discount: '',
        total: "",
        description: "",
      };
      that.category = {
        is_business: '',
        instant: "",
        business_id:[],
        start_time:'',
        end_time:''
      }
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/order/coupon/add',
          data: {
            row: that.Row,
            category: that.category
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    // 编辑保存
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/order/coupon/edit',
          data: {
            id: that.editId,
            row: that.Row,
            category: that.category
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
    //获取编辑内容
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.coupon_id
      axios.get('/admin/order/coupon/read', {   // 详情
        params: {
          id: data.coupon_id
        }
      }).then(function (res) {
        console.log(res.data.data);
        that.Row = {
          name: res.data.data.row.name,
          type: res.data.data.row.type,
          dedicated: res.data.data.row.dedicated.toString(),
          status: res.data.data.row.status.toString(),
          discount: res.data.data.row.discount,
          total: res.data.data.row.total,
          description: res.data.data.row.description,
          goods_ids: res.data.data.row.goods_ids.map(parseInt),
        }
        that.category = {
          is_business: res.data.data.category.is_business.toString(),
          instant: res.data.data.category.instant,
          time: res.data.data.category.time,
          business_id: res.data.data.category.business_id.map(parseInt),
          start_time: res.data.data.category.start_time_text,
          end_time: res.data.data.category.end_time_text,

        }
      })
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false;
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.coupon_id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
        method: 'DELETE',
        url: '/admin/order/coupon/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
      })
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
    },
  }
}
</script>


<style scoped> 
.NavLiName{
  min-width: 84px;
}
</style>
